.scroll {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(134, 134, 134, 0.3);
  border-radius: 5px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.infoGreetingsJap {
  color: #101920;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.infoGreetingsJapMobile {
  color: #101920;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.infoGreetingsEngl {
  color: #868686;
  font-size: 24px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  font-variant: all-small-caps;
}
.infoGreetingsEnglMobile {
  color: #868686;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  font-variant: all-small-caps;
}
.infoGreetingsText {
  color: #101920;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-variant: all-small-caps;
}
.infoGreetingsTextMobile {
  color: #101920;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-variant: all-small-caps;
  /*text-align: justify;*/
  /*text-justify: auto ;*/
}
.infoGreetingsGoodLuckJap {
  color: #101920;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.infoGreetingsGoodLuckJapMobile {
  color: #101920;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

