@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap");

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.AppTextMain {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

.AppTexJap {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 30px;
}

.HeaderMain {
  width: auto;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-variant: all-small-caps;
  padding-bottom: 10px;
}
.HeaderMainMobile {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-variant: all-small-caps;
  padding-bottom: 10px;
}

.SelectedLevel {
  position: relative;
  width: auto;
  height: 41px;
  left: 0;
  top: 100px;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 41px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #101920;
}
.SelectedLevelMobile {
  position: absolute;
  width: auto;
  height: auto;
  left: 30px;
  top: -4px;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 41px;

  display: flex;
  /*align-items: start;*/
  text-align: left;
  text-transform: uppercase;
  color: #101920;
}

.SelectedInputs {
  position: relative;
  width: auto;
  height: 41px;
  left: 0;
  top: 100px;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 41px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #101920;
}
.SelectedInputsMobile {
  position: absolute;
  width: auto;
  height: auto;
  left: 30px;
  top: 24px;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 41px;

  display: flex;
  /*align-items: start;*/
  text-align: left;
  text-transform: uppercase;

  color: #101920;
}
.hintButtonMobile {
}
