.TitleKanji {
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #101920;
}
.TitleKanjiMobile {
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #101920;
}

.TitleMain {
  position: relative;
  top: -15px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  font-variant: all-small-caps;
  color: #868686;
}

.TitleMainMobile {
  position: relative;
  top: -15px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  font-variant: all-small-caps;
  color: #868686;
}

