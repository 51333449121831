.footer {
  position: absolute;
  width: 104px;
  height: 18px;
  bottom: -20px;
  font-family: "Roboto", sans-serif;

  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: center;
  font-variant: all-small-caps;

  color: #868686;
}

