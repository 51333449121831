.Button {
  width: 150px;
  height: 30px;
  border-radius: 2px;
  cursor: pointer;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-variant: all-small-caps;
}

.ButtonMobile {
  width: 200px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-variant: all-small-caps;
}

