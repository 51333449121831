.CardKanji {
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 116px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #101920;
}

.CardText {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  font-variant: all-small-caps;
  justify-content: center;
  color: #d9d7dc;
}

.hintHeaderKanji {
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  color: #101920;
  background-color: #e6e1e7;
}
